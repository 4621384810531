import React, { SVGProps } from 'react';

export const ArrowDown = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        d="M3.26583 5.26292C3.61731 4.91144 4.18715 4.91145 4.53862 5.26292L8.0018 8.7261L11.4632 5.26481C11.8146 4.91334 12.3845 4.91335 12.736 5.26483C13.0874 5.6163 13.0874 6.18615 12.7359 6.53762L8.70889 10.5646C8.31836 10.9551 7.6852 10.9551 7.29468 10.5646L3.26583 6.53571C2.91436 6.18424 2.91436 5.61439 3.26583 5.26292Z"
        fill="currentcolor"
      />
    </svg>
  );
};
