import React, { SVGProps } from 'react';

export const Karma = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        d="M0.682768 8.9986C-0.220642 8.67338 -0.232904 7.38053 0.677991 7.0769C3.69916 6.06984 6.06987 3.69913 7.07693 0.67796C7.38056 -0.232935 8.67341 -0.220672 8.99864 0.682737C10.0474 3.59611 12.4039 5.95252 15.3172 7.00133C16.2206 7.32656 16.2329 8.61941 15.322 8.92304C12.3008 9.9301 9.93013 12.3008 8.92307 15.322C8.61944 16.2329 7.32659 16.2206 7.00136 15.3172C5.95255 12.4038 3.59614 10.0474 0.682768 8.9986Z"
        fill="currentcolor"
      />
    </svg>
  );
};
