import React, { SVGProps } from 'react';

export const Gem = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        d="M7.09998 0.239628C7.6569 -0.0819108 8.34305 -0.0819105 8.89997 0.239629L14.2707 3.3404C14.8276 3.66194 15.1707 4.25617 15.1707 4.89924V11.1008C15.1707 11.7439 14.8276 12.3381 14.2707 12.6596L8.89997 15.7604C8.34305 16.0819 7.6569 16.0819 7.09997 15.7604L1.72928 12.6596C1.17236 12.3381 0.829285 11.7439 0.829285 11.1008V4.89924C0.829285 4.25617 1.17236 3.66194 1.72928 3.3404L7.09998 0.239628Z"
        fill="currentcolor"
      />
    </svg>
  );
};

export default Gem;
