import React, { SVGProps } from 'react';

export const Actions = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        d="M9.41562 1.14762C9.56464 0.110488 8.22087 -0.428426 7.61198 0.424274L1.18905 9.41895C0.716414 10.0808 1.18955 11.0001 2.00285 11.0001L7.1383 11.0001L6.58474 14.8525C6.43572 15.8896 7.77946 16.4285 8.38838 15.5758L14.8109 6.58215C15.2836 5.92027 14.8104 5.00101 13.9971 5.001L8.86197 5.00096L9.41562 1.14762Z"
        fill="currentcolor"
      />
    </svg>
  );
};
