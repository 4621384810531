import React, { SVGProps } from 'react';

export const ArrowRight = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        d="M5.26988 3.26489C5.62135 2.91343 6.1912 2.91343 6.54267 3.26491L10.5696 7.29197C10.9601 7.6825 10.9601 8.31565 10.5696 8.70618L6.54076 12.735C6.18929 13.0865 5.61944 13.0865 5.26797 12.735C4.9165 12.3835 4.9165 11.8137 5.26797 11.4622L8.73115 7.99906L5.26986 4.53769C4.91839 4.18621 4.9184 3.61636 5.26988 3.26489Z"
        fill="currentcolor"
      />
    </svg>
  );
};
