import React, { SVGProps } from 'react';

export const Karma2 = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        d="M9.29871 3.9369C8.90347 3.79462 8.8981 3.229 9.29662 3.09616C10.6184 2.65557 11.6556 1.61838 12.0962 0.296621C12.229 -0.101896 12.7946 -0.0965308 12.9369 0.298711C13.3958 1.57331 14.4267 2.60424 15.7013 3.0631C16.0965 3.20538 16.1019 3.771 15.7034 3.90384C14.3816 4.34443 13.3444 5.38162 12.9038 6.70338C12.771 7.1019 12.2054 7.09653 12.0631 6.70129C11.6042 5.42669 10.5733 4.39576 9.29871 3.9369ZM0.512076 10.749C-0.165481 10.5051 -0.174678 9.53542 0.508493 9.3077C2.77437 8.5524 4.5524 6.77437 5.3077 4.50849C5.53542 3.82532 6.50506 3.83452 6.74898 4.51208C7.53559 6.69711 9.30289 8.46441 11.4879 9.25102C12.1655 9.49494 12.1747 10.4646 11.4915 10.6923C9.22563 11.4476 7.4476 13.2256 6.6923 15.4915C6.46458 16.1747 5.49494 16.1655 5.25102 15.4879C4.46441 13.3029 2.69711 11.5356 0.512076 10.749Z"
        fill="currentcolor"
      />
    </svg>
  );
};
