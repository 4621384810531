import React, { SVGProps } from 'react';

export const Fire = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99999 16C11.666 16 14.6379 13.2139 14.6379 9.77698C14.6379 5.83237 10.2346 1.64882 8.60066 0.232249C8.25291 -0.0692546 7.74704 -0.0692819 7.39925 0.232188C5.76531 1.64852 1.36212 5.83133 1.36212 9.77698C1.36212 13.2139 4.33399 16 7.99999 16ZM8 14C9.55361 14 10.8131 12.7405 10.8131 11.1869C10.8131 9.90854 9.86071 8.55083 8.73967 7.32628C8.3414 6.89124 7.65861 6.89124 7.26034 7.32628C6.13929 8.55083 5.18695 9.90854 5.18695 11.1869C5.18695 12.7405 6.4464 14 8 14Z"
        fill="currentcolor"
      />
    </svg>
  );
};
