import React, { SVGProps } from 'react';

export const Loading = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        d="M8 14.2C4.57583 14.2 1.8 11.4242 1.8 8C1.8 4.57583 4.57583 1.8 8 1.8C11.4224 1.8 14.1971 4.57291 14.2 7.99459C14.2 7.99638 14.2 7.99818 14.2 7.99998C14.2 8.49703 14.6029 8.89998 15.1 8.89998C15.597 8.89998 16 8.49704 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4173 3.58015 15.9984 7.99709 16L8 16C8.49706 16 8.90001 15.5971 8.90001 15.1C8.90001 14.603 8.49706 14.2 8 14.2Z"
        fill="currentcolor"
      />
    </svg>
  );
};
