import React, { SVGProps } from 'react';

export const ArrowLeft = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        d="M10.734 3.26586C11.0855 3.61734 11.0855 4.18718 10.734 4.53866L7.27086 8.00183L10.7322 11.4632C11.0836 11.8147 11.0836 12.3845 10.7321 12.736C10.3807 13.0875 9.81081 13.0875 9.45934 12.736L5.43238 8.70892C5.04187 8.31839 5.04187 7.68523 5.43239 7.29471L9.46126 3.26586C9.81273 2.91439 10.3826 2.91439 10.734 3.26586Z"
        fill="currentcolor"
      />
    </svg>
  );
};
