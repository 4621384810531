import React, { SVGProps } from 'react';

export const ArrowLeftLg = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        d="M11.0098 1.26605C11.3613 1.61752 11.3613 2.18737 11.0098 2.53884L5.54665 8.00201L11.0079 13.4634C11.3594 13.8149 11.3594 14.3847 11.0079 14.7362C10.6564 15.0876 10.0866 15.0876 9.73513 14.7362L3.70818 8.7091C3.31766 8.31857 3.31766 7.68541 3.70818 7.29489L9.73704 1.26604C10.0885 0.914573 10.6584 0.914574 11.0098 1.26605Z"
        fill="currentcolor"
      />
    </svg>
  );
};
