import { SVGProps } from 'react';

export const CrossLg = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        d="M2.53862 1.26287C2.18715 0.9114 1.61731 0.911399 1.26583 1.26287C0.91436 1.61434 0.914359 2.18419 1.26583 2.53566L6.7289 7.99874L1.26586 13.4617C0.914379 13.8132 0.914372 14.383 1.26584 14.7345C1.61731 15.086 2.18716 15.086 2.53863 14.7345L8.00001 9.27321L13.4632 14.7364C13.8146 15.0879 14.3845 15.0879 14.736 14.7364C15.0874 14.3849 15.0874 13.8151 14.736 13.4636L9.27291 8.00053L14.7359 2.53757C15.0874 2.18611 15.0874 1.61626 14.736 1.26478C14.3845 0.913305 13.8146 0.913298 13.4632 1.26477L8.0018 6.72605L2.53862 1.26287Z"
        fill="currentcolor"
      />
    </svg>
  );
};
