import { SVGProps } from 'react';

export const Like = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 16 14" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.292 1.57649C15.7679 3.05756 15.898 5.39354 14.6464 7.02507C14.5931 7.09447 14.537 7.16307 14.4787 7.2298L14.4875 7.23746L9.10927 13.1456C8.51423 13.7993 7.48584 13.7993 6.8908 13.1456L1.51231 7.23722L1.52095 7.22935C1.4627 7.16261 1.40696 7.09448 1.35371 7.02508C0.102065 5.39354 0.232178 3.05754 1.70807 1.57647C3.20494 0.074339 5.59198 -0.0467442 7.23216 1.29626L8.00004 1.925L8.76788 1.29628C10.4081 -0.0467175 12.7951 0.0743656 14.292 1.57649ZM6.09181 2.68894L8.00004 4.25142L9.90823 2.68897C10.8314 1.93307 12.1735 2.00065 13.0169 2.84705C13.8868 3.71994 13.9301 5.12127 13.1228 6.04597L12.7659 6.45479L8.00003 11.6902L3.23542 6.45616L2.87728 6.04596C2.06993 5.12126 2.11324 3.71992 2.98309 2.84702C3.82653 2.00062 5.16864 1.93304 6.09181 2.68894Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const LikeFull = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1.70807 1.57647C3.20494 0.074339 5.59198 -0.0467441 7.23216 1.29626L8.00004 1.925L8.76788 1.29628C10.4081 -0.0467175 12.7951 0.0743656 14.292 1.57649C15.8308 3.12071 15.9066 5.5943 14.4787 7.2298L14.4877 7.23719L9.10927 13.1456C8.51423 13.7993 7.48584 13.7993 6.8908 13.1456L1.51231 7.23722L1.52135 7.22981C0.0934163 5.5943 0.169249 3.12069 1.70807 1.57647Z"
        fill="currentColor"
      />
    </svg>
  );
};
