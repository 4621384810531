import React, { SVGProps } from 'react';

export const Cross = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        d="M4.53862 3.26287C4.18715 2.9114 3.61731 2.9114 3.26583 3.26287C2.91436 3.61434 2.91436 4.18419 3.26583 4.53566L6.7289 7.99874L3.26586 11.4617C2.91438 11.8132 2.91437 12.383 3.26584 12.7345C3.61731 13.086 4.18716 13.086 4.53863 12.7345L8.00001 9.27321L11.4632 12.7364C11.8146 13.0879 12.3845 13.0879 12.736 12.7364C13.0874 12.3849 13.0874 11.8151 12.736 11.4636L9.2729 8.00053L12.7359 4.53757C13.0874 4.18611 13.0874 3.61626 12.736 3.26478C12.3845 2.91331 11.8146 2.9133 11.4632 3.26477L8.0018 6.72605L4.53862 3.26287Z"
        fill="currentcolor"
      />
    </svg>
  );
};
