import { SVGProps } from 'react';

export const Blocks = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 56 56" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.0065318 6.3C0.0065318 2.8206 2.82714 0 6.30653 0H18.5565C22.0359 0 24.8565 2.8206 24.8565 6.3V18.55C24.8565 22.0294 22.0359 24.85 18.5565 24.85H6.30653C2.82713 24.85 0.0065318 22.0294 0.0065318 18.55V6.3Z"
        fill="currentColor"
      />
      <path
        d="M0 37.4501C0 33.9707 2.82061 31.1501 6.3 31.1501H18.55C22.0294 31.1501 24.85 33.9707 24.85 37.4501V49.7001C24.85 53.1795 22.0294 56.0001 18.55 56.0001H6.29999C2.8206 56.0001 0 53.1795 0 49.7001V37.4501Z"
        fill="currentColor"
      />
      <path
        d="M37.4501 31.1501C33.9707 31.1501 31.1501 33.9707 31.1501 37.4501V49.7001C31.1501 53.1795 33.9707 56.0001 37.4501 56.0001H49.7001C53.1795 56.0001 56.0001 53.1795 56.0001 49.7001V37.4501C56.0001 33.9707 53.1795 31.1501 49.7001 31.1501H37.4501Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.1583 6.29999C31.1583 2.8206 33.9789 0 37.4583 0H49.7001C53.1795 0 56.0001 2.82061 56.0001 6.3V18.55C56.0001 22.0294 53.1795 24.85 49.7001 24.85H37.4583C33.9789 24.85 31.1583 22.0294 31.1583 18.55V6.29999ZM37.4583 6.29999L49.7001 6.3V18.55L37.4583 18.55V6.29999Z"
        fill="currentColor"
      />
    </svg>
  );
};
