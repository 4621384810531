import React, { SVGProps } from 'react';

export const Login = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        d="M9.9 1.1001C9.40294 1.1001 9 1.50304 9 2.0001C9 2.49715 9.40294 2.9001 9.9 2.9001H12.1C12.7075 2.9001 13.2 3.39258 13.2 4.0001V12.1002C13.2 12.7078 12.7075 13.2002 12.1 13.2002H9.89931C9.40225 13.2002 8.99931 13.6032 8.99931 14.1002C8.99931 14.5973 9.40225 15.0002 9.89931 15.0002H12.1C13.7016 15.0002 15 13.7019 15 12.1002V4.0001C15 2.39847 13.7016 1.1001 12.1 1.1001H9.9Z"
        fill="currentcolor"
      />
      <path
        d="M7.26923 4.26434C6.91776 3.91287 6.34791 3.91286 5.99644 4.26433C5.64496 4.6158 5.64495 5.18565 5.99642 5.53712L7.55774 7.0985H1.89878C1.40172 7.0985 0.998779 7.50145 0.998779 7.9985C0.998779 8.49556 1.40172 8.8985 1.89878 8.8985H7.5577L5.99453 10.4617C5.64306 10.8131 5.64305 11.383 5.99453 11.7345C6.346 12.0859 6.91585 12.0859 7.26732 11.7345L10.2962 8.70561C10.6867 8.31509 10.6867 7.68193 10.2962 7.2914L7.26923 4.26434Z"
        fill="currentcolor"
      />
    </svg>
  );
};
